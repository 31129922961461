import React, { useEffect } from 'react';
import './App.css';

function App() {

  useEffect(() => {
    // Update the document title using the browser API
    document.title = `GGR Holdings Web Ticket Services`;
  });

  return (
    <div className="App">
      <header className="App-header">
        <h1> GGR Holdings</h1>
		<h2>Web Ticket Services</h2>
        <p>
          &nbsp;
        </p>
        <a
          className="App-link"
          href="mailto:ggradmin@ggrholdings.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Support
        </a>
      </header>
    </div>
  );
}

export default App;
